import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';
import { HeaderComponent } from '../header/header.component';
import { allLocalStorage } from "../../constantes";
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {

    @Output() close = new EventEmitter();

    public menus: any;
    public showModal: boolean = false;
    public menuEntriesCount = 0;
    public titles: Array<string> = [];
    public menuData: Array<any> = [];
    public username = '';

    constructor(
        private header: HeaderComponent,
        private menuService: MenuService,
        private router: Router,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.username = localStorage.getItem(allLocalStorage.username) ? localStorage.getItem(allLocalStorage.username) + '' : '';
        this.menuService.getMenu()
            .subscribe({
                next: (res: any) => {
                    this.menus = res;
                    //Numero de opciones del menu
                    this.menuEntriesCount = Object.keys(this.menus).length;
                    this.setTitles();
                    this.setMenuData();
                }}
            );
    }

    public setTitles(){ //Mete en un array los nombres (claves del objeto recibido) de los apartados del menú
        for (let i = 0; i < this.menuEntriesCount; i++) {
            this.titles.push((Object.keys(this.menus)[i]).toString());
        }
    }

    public setMenuData(){ //Mete en un array los datos (valores del objeto recibido) de los apartados del menú
        //Quitamos herramientas del menu para visualizarlo por separado
        for (let i = 0; i < this.menuEntriesCount; i++) {
            if (this.titles[i] !== "Herramientas" && this.titles[i] !== "Informes"){
                this.menuData.push(Object.values(this.menus)[i]);
            }
        }
    }

    public gotoAndClose(item: any, title: string) { //Si el enlace es el de herramientas te lleva directo. Si newAdmin es true te lleva a la url, si no lo es, te lleva a una url externa
        if (title === this.titles[this.titles.length - 1]){
            this.router.navigate(['herramientas/']).then(() => this.close.emit(true));
        } else if (title === this.titles[this.titles.length - 2]){
            this.router.navigate(['herramientas/informes']).then(() => this.close.emit(true));
        } else {
            if (item.newAdmin) {
                this.router.navigate([item.url]).then(() => this.close.emit(true));
            } else if (this.auth.getIncognitoMode() || this.auth.iOS()) {
                window.location.href = environment.oldDigoFront + item.url + (item.url?.includes('?') ? '&' : '?') + 'jwt=' + this.auth.getToken() + '&noFrameMode=1';
            } else {
                this.router.navigate(['herramientas/' + title + '-' + item.label + '/' + item.url]);
            }
        }
        this.header.onShow();
    }

    public changePassword(change: boolean) {
        this.showModal = change;
    }
    
    public closeSession() {
        localStorage.clear();
        window.location.href = environment.oldDigoFront + '/signin.itx?ac=999';
    }
}
