import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-custom-input',
    templateUrl: './custom-input.component.html',
    styleUrls: ['./custom-input.component.scss'],
})
export class CustomInputComponent implements OnInit {
    @Input() id: string;
    @Input() control: FormControl;
    @Input() controlName: string;
    @Input() title: string;
    @Input() readonly: boolean;
    @Input() type: string;
    @Input() maxlength: string;
    @Input() screenName: string;

    constructor() {}

    ngOnInit(): void {}
}