import { Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalRef: NzModalRef;

  constructor(private modal: NzModalService) { }

  public info(title: string, content: string) {
    this.modalRef = this.modal.info({
      nzTitle: title,
      nzContent: content,
      nzOkText: 'Aceptar',
    });
  }

  public success(title: string, content: string = '') {
    this.modalRef = this.modal.success({
      nzTitle: title,
      nzContent: content,
      nzOkText: 'Aceptar',
      nzOnOk: () => new Promise(resolve => this.modalRef.destroy(true)),
    });
    return this.modalRef.afterClose;
  }

  public error(title: string, content: string = ''): void {
    this.modalRef = this.modal.error({
      nzTitle: title,
      nzContent: content,
      nzOkText: 'Aceptar',
    });
  }

  public confirm(title: string, content: string = '') {
    this.modalRef = this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: 'Aceptar',
      nzCancelText: 'Cancelar',
      nzOnOk: () => new Promise(resolve => this.modalRef.destroy(true)),
      nzOnCancel: () => new Promise(resolve => this.modalRef.destroy(false)),
    });

    return this.modalRef.afterClose;
  }
}
