import { Injectable } from '@angular/core';
import { pageLimit } from 'src/app/shared/constantes';
import { AuthService } from '../../auth.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { IListPaginator } from 'src/app/core/models/lists.models';

@Injectable({
  providedIn: 'root'
})
export class BlockUsersService extends ApiService{
  private paginator: IListPaginator = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};

  constructor(
    auth: AuthService,
    http: HttpClient,
) {
    super(auth, http);
}

  public checkSuperAdmin(){
    var info = this.auth.getUserInfo();
    if (info?.superadmin) {
      return true;
    }
    return false;
  }

  public setPaginator(pagintor: any) {
    this.paginator = pagintor;
  }

  public getPaginator(): any {
    return this.paginator;
  }

  public getActiveBlockedUsers(): Observable<any> {
    return this.http.post<any>(environment.digoApiEndpoint + 'user/block/current', {username: "", page: this.paginator.currentPage, limit:this.paginator.limit}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
  }

  public getHistoricBlockedUsers(): Observable<any> {
    return this.http.post<any>(environment.digoApiEndpoint + 'user/block/historic', {username: "", page: this.paginator.currentPage, limit:this.paginator.limit}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
  }

  public searchBlockedUsers(username:string): Observable<any> {
    return this.http.post<any>(environment.digoApiEndpoint + 'user/block/search', {username: username}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));

  }

  public modifyBlockedUser(form: any): Observable<any>{
    return this.http.post<any>(environment.digoApiEndpoint + 'user/block/modify', {user_id: form.user_id, status: form.blocked_status, comments: form.comments}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
  }
}
